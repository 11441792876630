import React from "react";
import { Modal, Typography, Descriptions } from "antd";
import PropTypes from "prop-types";

const { Paragraph } = Typography;

export const SubmitConfirmationModal = ({
  isVisible,
  onOk,
  onCancel,
  userInfo,
  questions,
}) => {
  const { name, cellPhone, address } = userInfo;
  return (
    <Modal
      title="Konfirmasi Pemilihan"
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Kirim"
      cancelText="Batalkan"
    >
      <Paragraph>Apakah anda yakin untuk memasukkan data berikut?</Paragraph>
      <Descriptions bordered>
        <Descriptions.Item label="Nama" span={3}>
          {name}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="No HP">
          {cellPhone}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Alamat">
          {address}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Total pilihan">
          {questions.length}
        </Descriptions.Item>
        {questions.length > 0 && (
          <Descriptions.Item span={3} label=" Pilihan">
            {questions.reduce(
              (combined, q) => combined + (combined ? `, ${q.name}` : q.name),
              ""
            )}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Modal>
  );
};
SubmitConfirmationModal.defaultProps = {
  isVisible: false,
  onOk: () => {},
  onCancel: () => {},
  questions: [],
  userInfo: { name: "", cellPhone: "", address: "" },
};
SubmitConfirmationModal.propTypes = {
  isVisible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    cellPhone: PropTypes.string,
    address: PropTypes.string,
  }),
  questions: PropTypes.array,
};

export default SubmitConfirmationModal;
