import React from "react";
import PropTypes from "prop-types";
import { Card, Typography } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { questionSelected, selectQuestionById } from "../data/votingSlice";

const { Title } = Typography;

const CheckboxCard = ({ questionId, disabled }) => {
  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const dispatch = useDispatch();

  const handleSelection = () => {
    if (!disabled) {
      dispatch(questionSelected({ id: question.id }));
    }
  };

  return (
    <div
      role="button"
      onClick={handleSelection}
      onKeyUp={() => {}}
      tabIndex={0}
      style={{ position: "relative" }}
    >
      <Card
        style={{ marginTop: 16 }}
        hoverable
        className={question.selected ? "selected-color" : null}
        cover={<img alt={question.name} src={question.image} />}
      >
        <Title level={4} style={{ textAlign: "center" }}>
          {question.name}
        </Title>
      </Card>
      {question.selected && (
        <div
          style={{
            position: "absolute",

            top: 0,
            margin: "0.75em 0em 0em 0.75em",
          }}
        >
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: "28pt" }}
          />
        </div>
      )}
    </div>
  );
};

CheckboxCard.propTypes = {
  questionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CheckboxCard;
