import React from "react";
import { List, Col, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";
import CheckboxCard from "./CheckboxCard";
import {
  getPollData,
  getPollStatus,
  selectAllQuestions,
} from "../data/votingSlice";

const CheckboxGallery = () => {
  const poll = useSelector((state) => getPollData(state));
  const pollStatus = useSelector((state) => getPollStatus(state));
  const questions = useSelector((state) => selectAllQuestions(state));

  return (
    <div>
      {pollStatus === "loading" ? (
        <div style={{ paddingTop: "20px" }}>
          <Row gutter={[5, 0]}>
            <Col flex="auto" style={{ textAlign: "center" }}>
              <Skeleton.Image />
            </Col>
            <Col flex="auto" style={{ textAlign: "center" }}>
              <Skeleton.Image />
            </Col>
            <Col flex="auto" style={{ textAlign: "center" }}>
              <Skeleton.Image />
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <List
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={questions}
            renderItem={(question) => (
              <List.Item>
                <CheckboxCard
                  questionId={question.id}
                  disabled={poll.disabled}
                />
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default CheckboxGallery;
