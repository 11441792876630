import React from "react";
import PropTypes from "prop-types";
import { Badge, Space } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";

export const FloatingCircle = ({ text, ...props }) => (
  <Space>
    {text !== "0" && (
      <Space
        style={{
          background: "white",
          padding: 5,
          border: "1px solid #dddddd",
        }}
      >
        <Badge count={<UsergroupAddOutlined style={{ fontSize: "16pt" }} />} />
        <Badge count={text} {...props} />
      </Space>
    )}
  </Space>
);

FloatingCircle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FloatingCircle;
