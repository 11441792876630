import React from "react";
import PropTypes from "prop-types";
import { format, fromUnixTime } from "date-fns";
import { withRouter } from "react-router-dom";
import { Typography, Spin, Descriptions, Image, message, Button } from "antd";
import DOMPurify from "dompurify";

const { Title } = Typography;

const GET_EVENT_API = `${process.env.REACT_APP_SERVER_URL}/getEventDetail`;
const CONFIRM_PAYMENT_API = `${process.env.REACT_APP_SERVER_URL}/confirmPayment`;
const RECAPTCHA_KEY = "recaptcha-key";
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;

class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      att: null,
      allowEdit: false,
      disableConfirmButton: false,
      recaptchaLoaded: false,
    };
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const eventId = match.params.id;
    const { attId, apiKey = undefined } = match.params;

    if (!document.getElementById(RECAPTCHA_KEY)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = RECAPTCHA_URL;
      script.id = RECAPTCHA_KEY;
      script.onload = this.recaptchaScriptLoaded;
      document.body.appendChild(script);
    } else {
      this.setState({ recaptchaLoaded: true });
    }

    try {
      const response = await fetch(
        `${GET_EVENT_API}?eid=${eventId}&aid=${attId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
            api: apiKey || undefined,
          },
        }
      );
      const jsonResp = await response.json();
      this.setState({
        event: { id: eventId, ...jsonResp.event },
        att: { id: attId, ...jsonResp.attendance },
        allowEdit: jsonResp.allowEdit,
      });
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
    }
  };

  recaptchaScriptLoaded = () => {
    this.setState({ recaptchaLoaded: true });
  };

  showPaymentControl = () => {
    const { att, event, allowEdit, disableConfirmButton, recaptchaLoaded } =
      this.state;

    return recaptchaLoaded && allowEdit && !att.confirmed ? (
      <Button
        disabled={disableConfirmButton}
        loading={disableConfirmButton}
        onClick={async () => {
          this.setState({ disableConfirmButton: true });
          const token = await window.grecaptcha.execute(
            process.env.REACT_APP_RECAPTCHA_SITE_KEY,
            { action: "submit" }
          );

          if (!token || token === "") {
            message.error(
              "Failed to verify recaptcha, please retry.",
              process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT
            );
            return;
          }

          const response = await fetch(CONFIRM_PAYMENT_API, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
            },
            body: JSON.stringify({
              token,
              event_id: event.id,
              attendance_id: att.id,
            }),
          });
          const jsonResp = await response.json();
          if (jsonResp.result) {
            window.location.reload(false);
          } else {
            message.error(
              "Confirmasi pembayaran gagal",
              process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT
            );
            this.setState({ disableConfirmButton: false });
          }
        }}
      >
        Confirm Payment
      </Button>
    ) : (
      <div>{att.confirmed ? "Sudah Dikonfirmasi" : "Dalam Pengecekan"}</div>
    );
  };

  render() {
    const { event, att } = this.state;
    return (
      <div className="site-layout-content">
        {event && att ? (
          <Typography>
            <Title level={2}>
              Pendaftaran anda telah berhasil. Terima kasih.
            </Title>
            {/* {att.profilePhoto && (
              <Title level={3}>
                Mohon untuk memeriksa email anda, agar bisa menerima link untuk
                acara Zoom.
              </Title>
            )} */}
            <Title level={4}>
              Berikut ini adalah informasi pendaftaran anda.
            </Title>
            <Descriptions bordered>
              {att.zoom_url && (
                <Descriptions.Item label="Zoom URL" span={3}>
                  <a href={att.zoom_url}>Click here to join ZOOM</a>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Name" span={1.5}>
                {att.name}
              </Descriptions.Item>
              {att.receiptPayment && (
                <Descriptions.Item label="Pembayaran" span={1.5}>
                  {this.showPaymentControl()}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Email" span={1.5}>
                {att.email}
              </Descriptions.Item>
              <Descriptions.Item label="WhatsApp" span={1.5}>
                {att.whatsapp}
              </Descriptions.Item>
              {att.receiptPayment && (
                <Descriptions.Item label="Bukti Pembayaran" span={1.5}>
                  <Image width={150} src={att.receiptPayment} />
                </Descriptions.Item>
              )}
              {att.profilePhoto && (
                <Descriptions.Item label="Profile Photo" span={1.5}>
                  <Image width={150} src={att.profilePhoto} />
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Acara" span={1.5}>
                {event.name}
              </Descriptions.Item>
              <Descriptions.Item label="Tanggal" span={1.5}>
                {format(
                  fromUnixTime(event.start_date),
                  "iiii, d MMMM yyyy, HH:mm O"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="QR Tiket"
                span={3}
                style={{ textAlign: "center" }}
              >
                <div>
                  <Image src={att.qr_code} preview={false} />
                </div>
                <div>
                  <a href={att.qr_code} download="gloria-event-qr.png">
                    <Button>Simpan QR Code</Button>
                  </a>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Deskripsi" span={3}>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(event.description),
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
            {event.img && (
              <div style={{ paddingBottom: 10 }}>
                <Image width="100%" src={event.img} />
              </div>
            )}
          </Typography>
        ) : (
          <Spin />
        )}
      </div>
    );
  }
}

ThankYou.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ThankYou);
