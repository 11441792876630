import React from "react";
import { Route, Switch } from "react-router";
import { Layout, Image, Row, Col } from "antd";
import Default from "./components/Default";
import SMRegForm from "./components/forms/SMRegForm";
import RegForm from "./components/forms/RegForm";
import VotingForm from "./components/forms/VotingForm";
import VotingPageList from "./components/VotingPageList";
import { LDAnalytics } from "./components/analytics/LDAnalytics";
import ThankYou from "./components/ThankYou";
import "./App.less";

// import SocialMedia from "./components/SocialMedia";
import { PollThankYou } from "./components/PollThankYou";
import GuestBook from "./components/forms/GuestBook";
import { Musical } from "./components/Musical";
import { Shortcuts } from "./components/Shortcuts";
import HealthForm from "./components/forms/HealthForm";

const { Header, Footer, Content } = Layout;

const App = () => (
  <div className="App">
    <Layout className="layout">
      <Header>
        <div className="logo">
          <Row>
            <Col>
              <Image
                width={64}
                preview={false}
                src="https://storage.googleapis.com/gloria_assets_id/reg/logo192.png"
              />
            </Col>
            <Col>
              <div
                className="main-color"
                style={{
                  fontSize: 20,
                  fontWeight: 800,
                  marginLeft: 5,
                }}
              >
                GKA GLORIA
              </div>
            </Col>
          </Row>
        </div>
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <Switch>
          <Route path="/thankyou/:id/:attId/:apiKey?">
            <ThankYou />
          </Route>
          <Route path="/pollthankyou/:voteId">
            <PollThankYou />
          </Route>
          <Route path="/vbt">
            <SMRegForm />
          </Route>
          <Route path="/event/:id">
            <RegForm />
          </Route>
          <Route path="/form/:eventId/:attId">
            <HealthForm />
          </Route>
          <Route path="/e/:id">
            <Shortcuts />
          </Route>
          <Route path="/ldanalytics/:id/:date?">
            <LDAnalytics />
          </Route>
          <Route path="/voting/:pollId">
            <VotingForm />
          </Route>
          <Route path="/voting">
            <VotingPageList />
          </Route>
          <Route path="/childrenmusical">
            <GuestBook />
          </Route>
          <Route path="/musical/:id/:attid">
            <Musical />
          </Route>
          <Route path="/">
            <Default />
          </Route>
        </Switch>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        {/* <SocialMedia /> */}
        GKA Gloria ©2021
      </Footer>
    </Layout>
  </div>
);

export default App;
