import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Skeleton,
  Typography,
  Collapse,
  Badge,
  message,
  DatePicker,
} from "antd";
import { parseISO, format } from "date-fns";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DOMPurify from "dompurify";
import moment from "moment";

export const GET_LD_ANALYTICS =
  "https://us-central1-gloria-d8bf9.cloudfunctions.net/getLDAnalytics/";

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

const formatter = new Intl.DateTimeFormat("id", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const LDAnalytics = () => {
  const { id: groupId, date = format(new Date(), "yyyy-MM-dd") } = useParams();
  const history = useHistory();
  const [chartData, setChartData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(date);
  const [dateParams, setDateParams] = useState(date.split("-"));

  useEffect(() => {
    const loadAnalytics = async () => {
      try {
        const response = await fetch(
          `${GET_LD_ANALYTICS}?group=${groupId}&date=${currentDate}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
            },
          }
        );
        const jsonResp = await response.json();
        if (jsonResp.result) {
          setChartData(
            jsonResp.dayData.map(({ day, total }) => ({
              name: format(
                parseISO(
                  `${dateParams[0]}-${dateParams[1]}-${day}`,
                  "yyyy-MM-dd"
                ),
                "d LLL"
              ),
              amt: total,
              total,
            }))
          );
          setUserData(jsonResp.userData);
          setSummaryData(jsonResp.monthSummary);
        } else {
          message.error(jsonResp.error);
        }
      } catch (error) {
        message.error("Error loading analytics");
      }
    };

    if (!loading) {
      setLoading(true);
      loadAnalytics().then(() => {
        setLoading(false);
      });
    }
  }, [currentDate, dateParams]);

  if (loading) {
    return (
      <div className="site-layout-content">
        <Skeleton />
      </div>
    );
  }

  const userWithNotes = userData
    .filter(({ notes }) => notes !== "")
    // eslint-disable-next-line camelcase
    .map(({ user_id }) => user_id);

  const isoDate = parseISO(currentDate, "yyyy-MM-dd");
  const totalDaysInMonth = new Date(
    isoDate.getFullYear(),
    isoDate.getMonth() + 1,
    0
  ).getDate();
  const dateFormat = "YYYY-MM-DD";
  return (
    <div className="site-layout-content">
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
      >
        <Text style={{ marginRight: "0.5em" }}>Pilih Tanggal: </Text>
        <DatePicker
          defaultValue={moment(currentDate, dateFormat)}
          disabledDate={(d) =>
            moment().diff(d, "hours") < 0 ||
            moment("2022-01-01", dateFormat).diff(d, "days") > 0
          }
          onChange={(_, dateString) => {
            setCurrentDate(dateString);
            setDateParams(dateString.split("-"));
            history.replace(`/ldanalytics/${groupId}/${dateString}`);
          }}
        />
      </div>
      <Title className="main-color" level={2}>
        Chart Bulan{" "}
        {new Intl.DateTimeFormat("id", {
          year: "numeric",
          month: "long",
        }).format(parseISO(currentDate, "yyyy-MM-dd"), "LLLL yyyy")}
      </Title>
      <Paragraph>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />

              <Line type="monotone" dataKey="total" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Paragraph>
      <Title className="main-color" level={2}>
        Absensi Lectio Divina{" "}
        {formatter.format(
          parseISO(currentDate, "yyyy-MM-dd"),
          "EEEE, d LLLL yyyy"
        )}
      </Title>
      {userData.length === 0 && <Text>No data available.</Text>}
      <Collapse defaultActiveKey={userWithNotes}>
        {userData.map(({ user_id: userId, name, completed, notes }) => (
          <Panel
            header={
              <div style={{ display: "flex" }}>
                <Text>{completed ? "✅" : "⬜️"}</Text>
                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: "1em",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text strong>{name}</Text>{" "}
                  <Badge
                    style={{ backgroundColor: "#014890" }}
                    count={`${summaryData[userId].completed} of ${totalDaysInMonth}`}
                  />
                </div>
              </div>
            }
            key={userId}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  notes ? notes.replace(/\n/g, "<br/>") : "Tidak ada jurnal."
                ),
              }}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default LDAnalytics;
