import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  Typography,
  Form,
  Input,
  Button,
  message,
  Spin,
  Divider,
  Image,
  Row,
  Col,
} from "antd";
import {
  CalendarTwoTone,
  ClockCircleTwoTone,
  YoutubeOutlined,
} from "@ant-design/icons";
import { fromUnixTime, format, differenceInCalendarDays, add } from "date-fns";

const { Title, Text, Paragraph } = Typography;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
  style: {
    marginTop: 15,
  },
};

const EVENT_ID = "M8yPmQk481FbfJOwmQvn";
const REG_API = `${process.env.REACT_APP_SERVER_URL}/submitEventRegistrationData`;
const RECAPTCHA_KEY = "recaptcha-key";
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
const GET_EVENT_API = `${process.env.REACT_APP_SERVER_URL}/getEventDetail`;

class GuestBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: EVENT_ID,
      buttonLoading: false,
      recaptchaLoaded: false,
      event: null,
    };
  }

  componentDidMount = () => {
    if (!document.getElementById(RECAPTCHA_KEY)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = RECAPTCHA_URL;
      script.id = RECAPTCHA_KEY;
      script.onload = this.recaptchaScriptLoaded;
      document.body.appendChild(script);
    } else {
      this.setState({ recaptchaLoaded: true });
    }

    this.loadEvent();
  };

  loadEvent = async () => {
    const { eventId } = this.state;
    try {
      const response = await fetch(`${GET_EVENT_API}?eid=${eventId}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
        },
      });
      const jsonResp = await response.json();
      this.setState({
        event: jsonResp.event,
      });
      document.title = jsonResp.event.name;
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
    }
  };

  recaptchaScriptLoaded = () => {
    this.setState({ recaptchaLoaded: true });
  };

  registerUser = async (values, token) => {
    const { eventId } = this.state;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
      },
      body: JSON.stringify({
        token,
        event_id: eventId,
        formData: values,
        notUnique: true,
      }),
    };

    const response = await fetch(REG_API, requestOptions);
    const jsonResp = await response.json();
    if (jsonResp.result) {
      return jsonResp.attId;
    }
    throw jsonResp.error;
  };

  onFinish = async (values) => {
    const { history } = this.props;
    const { eventId } = this.state;

    this.setState({ buttonLoading: true });
    try {
      const token = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action: "submit" }
      );
      if (token && token !== "") {
        const attId = await this.registerUser(
          {
            ...values,
          },
          token
        );

        history.push(`/musical/${eventId}/${attId}`);
      } else {
        message.error(
          "Failed to verify recaptcha, please retry.",
          process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT
        );
        this.setState({ buttonLoading: false });
      }
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
      this.setState({ buttonLoading: false });
    }
  };

  render() {
    const { recaptchaLoaded, event, buttonLoading } = this.state;
    return (
      <div className="site-layout-content">
        {!recaptchaLoaded || !event ? (
          <Spin />
        ) : (
          <Typography>
            <Row gutter={[15, 0]}>
              <Col md={15} style={{ paddingBottom: 15 }}>
                <Image preview={false} src={event.img} />
              </Col>
              <Col md={9}>
                <Title
                  className="main-color"
                  style={{ fontWeight: 800 }}
                  level={2}
                >
                  {event.name}
                </Title>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(event.description),
                  }}
                />
                <Divider orientation="left">
                  <Text
                    className="main-color"
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                    }}
                  >
                    Event Date
                  </Text>
                </Divider>
                <div style={{ textAlign: "" }}>
                  <CalendarTwoTone />{" "}
                  <Text>
                    {differenceInCalendarDays(
                      fromUnixTime(event.end_date),
                      fromUnixTime(event.start_date)
                    ) > 0 ? (
                      <Text>
                        {format(fromUnixTime(event.start_date), "d MMMM yyyy")}-{" "}
                        {format(fromUnixTime(event.end_date), "d MMMM yyyy")}
                      </Text>
                    ) : (
                      <Text>
                        {format(
                          fromUnixTime(event.start_date),
                          "iiii, d MMMM yyyy"
                        )}
                      </Text>
                    )}
                  </Text>
                </div>
                <div>
                  <ClockCircleTwoTone />{" "}
                  <Text>
                    {format(fromUnixTime(event.start_date), "HH:mm")}{" "}
                    {format(fromUnixTime(event.start_date), "O") === "GMT+7"
                      ? "WIB"
                      : format(fromUnixTime(event.start_date), "O")}{" "}
                    /{" "}
                    {format(
                      add(fromUnixTime(event.start_date), { hours: 1 }),
                      "h:mm a"
                    )}{" "}
                    SGT
                  </Text>
                </div>
                <br />
                <div>
                  <b>Live on</b>
                </div>
                <div>
                  <YoutubeOutlined style={{ color: "red" }} />{" "}
                  <a
                    href="https://gvc.gkagloria.id/taam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    YouTube GKA Gloria Kota Satelit
                  </a>
                </div>
                <Divider orientation="left">
                  <Text
                    style={{ fontWeight: 600, fontSize: 20 }}
                    className="main-color"
                  >
                    Guest Book
                  </Text>
                </Divider>
                <Paragraph>
                  Please kindly fill up the form to get more up-to-date
                  information about the event.
                </Paragraph>
                <Form {...layout} layout="vertical" onFinish={this.onFinish}>
                  <Form.Item
                    name="name"
                    label="Name"
                    labelAlign="left"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="E-mail"
                    labelAlign="left"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter email in the correct format.",
                      },
                      {
                        required: true,
                        message: "Please enter your email.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="church"
                    label="Church / Organization"
                    labelAlign="left"
                  >
                    <Input placeholder="Ex: GKA Gloria" />
                  </Form.Item>

                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={buttonLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Typography>
        )}
      </div>
    );
  }
}

GuestBook.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(GuestBook);
