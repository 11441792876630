import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  Typography,
  Form,
  Input,
  Button,
  message,
  Spin,
  // DatePicker,
  Divider,
  Checkbox,
  Image,
  Row,
  Col,
} from "antd";
import {
  CalendarTwoTone,
  ClockCircleTwoTone,
  PushpinTwoTone,
} from "@ant-design/icons";
import {
  isAfter,
  fromUnixTime,
  format,
  differenceInCalendarDays,
} from "date-fns";

const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
  style: {
    marginTop: 15,
  },
};

const REG_API = `${process.env.REACT_APP_SERVER_URL}/submitEventRegistrationData`;
const RECAPTCHA_KEY = "recaptcha-key";
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
const GET_EVENT_API = `${process.env.REACT_APP_SERVER_URL}/getEventDetail`;

class RegForm extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      eventId: match.params.id,
      buttonLoading: false,
      recaptchaLoaded: false,
      termsNotAccepted: false,
      event: null,
    };
  }

  componentDidMount = () => {
    if (!document.getElementById(RECAPTCHA_KEY)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = RECAPTCHA_URL;
      script.id = RECAPTCHA_KEY;
      script.onload = this.recaptchaScriptLoaded;
      document.body.appendChild(script);
    } else {
      this.setState({ recaptchaLoaded: true });
    }

    this.loadEvent();
  };

  loadEvent = async () => {
    const { eventId } = this.state;
    try {
      const response = await fetch(`${GET_EVENT_API}?eid=${eventId}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
        },
      });
      const jsonResp = await response.json();
      this.setState({
        event: jsonResp.event,
      });
      document.title = jsonResp.event.name;
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
    }
  };

  recaptchaScriptLoaded = () => {
    this.setState({ recaptchaLoaded: true });
  };

  registerUser = async (values, token) => {
    const { eventId } = this.state;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
      },
      body: JSON.stringify({
        token,
        event_id: eventId,
        formData: values,
        notUnique: true,
      }),
    };

    const response = await fetch(REG_API, requestOptions);
    const jsonResp = await response.json();
    if (jsonResp.result) {
      return jsonResp.attId;
    }
    throw jsonResp.error;
  };

  onFinish = async (values) => {
    const { history } = this.props;
    const { eventId } = this.state;
    if (!values.termsCheckbox || values.termsCheckbox.length !== 5) {
      this.setState({ termsNotAccepted: true });
      return;
    }

    this.setState({ buttonLoading: true });
    try {
      const token = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action: "submit" }
      );
      if (token && token !== "") {
        const attId = await this.registerUser(values, token);

        history.push(`/thankyou/${eventId}/${attId}`);
      } else {
        message.error(
          "Failed to verify recaptcha, please retry.",
          process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT
        );
        this.setState({ buttonLoading: false });
      }
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
      this.setState({ buttonLoading: false });
    }
  };

  render() {
    const { recaptchaLoaded, event, buttonLoading, termsNotAccepted } =
      this.state;
    const formatter = new Intl.DateTimeFormat("id", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return (
      <div className="site-layout-content">
        {!recaptchaLoaded || !event ? (
          <Spin />
        ) : (
          <Typography>
            <Row gutter={[15, 0]}>
              <Col md={15} style={{ paddingBottom: 15 }}>
                <Image style={{}} src={event.img} />
              </Col>
              <Col md={9}>
                <Title
                  className="main-color"
                  style={{ fontWeight: 800 }}
                  level={2}
                >
                  {event.name}
                </Title>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(event.description),
                  }}
                />
              </Col>
            </Row>
            <Divider orientation="left">
              <Text
                className="main-color"
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                }}
              >
                Tanggal dan Waktu
              </Text>
            </Divider>
            <div style={{ textAlign: "" }}>
              <CalendarTwoTone />{" "}
              <Text>
                {differenceInCalendarDays(
                  fromUnixTime(event.end_date),
                  fromUnixTime(event.start_date)
                ) > 0 ? (
                  <Text>
                    {format(fromUnixTime(event.start_date), "d MMMM yyyy")}-{" "}
                    {format(fromUnixTime(event.end_date), "d MMMM yyyy")}
                  </Text>
                ) : (
                  <Text>
                    {formatter.format(
                      fromUnixTime(event.start_date),
                      "iiii, d MMMM yyyy"
                    )}
                  </Text>
                )}
              </Text>
            </div>
            <div>
              <ClockCircleTwoTone />{" "}
              <Text>
                {format(fromUnixTime(event.start_date), "HH:mm")}{" "}
                {format(fromUnixTime(event.start_date), "O") === "GMT+7"
                  ? "WIB"
                  : format(fromUnixTime(event.start_date), "O")}{" "}
              </Text>
            </div>
            {event.location && (
              <div>
                <PushpinTwoTone /> <Text>{event.location}</Text>
              </div>
            )}
            <Divider orientation="left">
              <Text
                style={{ fontWeight: 600, fontSize: 20 }}
                className="main-color"
              >
                Form Registrasi
              </Text>
            </Divider>
            <Form
              {...layout}
              onFinish={this.onFinish}
              hidden={
                event.attendanceCount >= event.max_attendance ||
                // eslint-disable-next-line no-underscore-dangle
                isAfter(new Date(), fromUnixTime(event.max_reg_date._seconds))
              }
            >
              <Form.Item
                name="name"
                label="Nama"
                labelAlign="left"
                rules={[{ required: true }]}
              >
                <Input placeholder="Nama lengkap" />
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                labelAlign="left"
                tooltip="Kami akan menggunakan email untuk pengiriman konfirmasi pendaftaran dan QR tiket."
                rules={[
                  {
                    type: "email",
                    message:
                      "Tolong memasukkan email dengan format yang benar.",
                  },
                  {
                    required: false,
                    message: "Tolong memasukkan email.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                name="birthday"
                label="Tanggal Lahir"
                labelAlign="left"
                tooltip="Digunakan untuk pencocokan dengan KTP."
                rules={[
                  {
                    required: true,
                    message: "Tolong masukkan tanggal lahir anda.",
                  },
                ]}
              >
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item> */}
              <Form.Item
                name="address"
                label="Alamat Rumah"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tolong masukkan alamat rumah anda.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="whatsapp"
                label="No HP"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tolong masukkan nomor HP anda.",
                  },
                ]}
              >
                <Input placeholder="Contoh: 08123123456" type="tel" />
              </Form.Item>
              <Form.Item name="termsCheckbox" label="" labelAlign="left">
                <Checkbox.Group>
                  <Row>
                    <Col>
                      <Text>Dengan ini, saya menyatakan:</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox value="term1" style={{ lineHeight: "32px" }}>
                        Telah membaca semua informasi protokol new normal
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox value="term2" style={{ lineHeight: "32px" }}>
                        Mengisi data registrasi dengan sebenar-benarnya
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox value="term3" style={{ lineHeight: "32px" }}>
                        Memutuskan untuk hadir di ibadah onsite dengan
                        pertimbangan yang matang tanpa paksaan.
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox value="term4" style={{ lineHeight: "32px" }}>
                        Bersedia mengikuti semua protokol yang berlaku.
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox value="term5" style={{ lineHeight: "32px" }}>
                        Telah divaksin Covid-19 sebanyak 2 kali.
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              {termsNotAccepted && (
                <Text type="danger">
                  Mohon untuk mencentang semua pernyataan di atas.
                </Text>
              )}
              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  Register
                </Button>
              </Form.Item>
            </Form>

            {event.attendanceCount >= event.max_attendance && (
              <Text>
                Mohon maaf, pendaftaran telah ditutup karena jumlah peserta
                telah melebihi limit yang ditentukan
              </Text>
            )}

            {isAfter(
              new Date(),
              // eslint-disable-next-line no-underscore-dangle
              fromUnixTime(event.max_reg_date._seconds)
            ) && (
              <Text>
                Mohon maaf, pendaftaran telah ditutup karena telah melewati
                batas tanggal pendaftaran.
              </Text>
            )}
          </Typography>
        )}
      </div>
    );
  }
}

RegForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(RegForm);
