import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  Typography,
  Form,
  Input,
  Button,
  Upload,
  message,
  Select,
  Divider,
  Image,
  Row,
  Col,
  Skeleton,
  Space,
} from "antd";
import { CalendarTwoTone, ClockCircleTwoTone } from "@ant-design/icons";
import {
  isAfter,
  fromUnixTime,
  format,
  differenceInCalendarDays,
} from "date-fns";
import PhoneInput from "react-phone-number-input";
import ImgCropIos from "../../thirdparty/ant-img-crop/index";

const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 4,
    },
  },
};

const VBZ_EVENT_ID = "Jm050Ba2h0BSsyCPp62T";
const REG_API = `${process.env.REACT_APP_SERVER_URL}/submitEventRegistrationData`;
const SIGNED_URL_API = `${process.env.REACT_APP_SERVER_URL}/getProfilePhotoSignedURL`;
const RECAPTCHA_KEY = "recaptcha-key";
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
const GET_EVENT_API = `${process.env.REACT_APP_SERVER_URL}/getEventDetail`;
const LOCAL_FORM_DATA_KEY = "localFormData";
class SMRegForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: VBZ_EVENT_ID,
      profilePhoto: [],
      paymentReceipt: [],
      buttonLoading: false,
      recaptchaLoaded: false,
      event: null,
      whatsapp: "",
    };
    this.formRef = React.createRef();
  }

  componentDidMount = () => {
    if (!document.getElementById(RECAPTCHA_KEY)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = RECAPTCHA_URL;
      script.id = RECAPTCHA_KEY;
      script.onload = this.recaptchaScriptLoaded;
      document.body.appendChild(script);
    } else {
      this.setState({ recaptchaLoaded: true });
    }

    this.loadEvent();
  };

  loadEvent = async () => {
    try {
      const response = await fetch(`${GET_EVENT_API}?eid=${VBZ_EVENT_ID}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
        },
      });
      const jsonResp = await response.json();
      this.setState({
        event: jsonResp.event,
      });
      document.title = jsonResp.event.name;
      this.loadFormDataFromLocalStorage();
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
    }
  };

  recaptchaScriptLoaded = () => {
    this.setState({ recaptchaLoaded: true });
  };

  registerUser = async (values) => {
    const { eventId } = this.state;
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: "submit" }
    );

    if (!token || token === "") {
      throw Error("Failed to verify recaptcha, please retry.");
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
      },
      body: JSON.stringify({
        token,
        event_id: eventId,
        formData: { ...values, confirmed: false },
      }),
    };

    const response = await fetch(REG_API, requestOptions);
    const jsonResp = await response.json();
    if (jsonResp.result) {
      return jsonResp.attId;
    }
    throw jsonResp.error;
  };

  uploadProfilePhoto = async (filePath, file) => {
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: "submit" }
    );

    if (!token || token === "") {
      throw Error("Failed to verify recaptcha, please retry.");
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: file,
    };

    const signedUrlResp = await fetch(
      `${SIGNED_URL_API}?filePath=${encodeURI(filePath)}&t=${token}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
        },
      }
    );
    const signedUrlJsonResp = await signedUrlResp.json();
    if (signedUrlJsonResp.result) {
      await fetch(signedUrlJsonResp.url, requestOptions);
    } else {
      throw signedUrlJsonResp.error;
    }
  };

  onFinish = async (values) => {
    const { profilePhoto, paymentReceipt, eventId } = this.state;
    const { history } = this.props;
    if (paymentReceipt.length !== 1) {
      message.error(
        "Tolong upload bukti pembayaran sebelum melakukan proses registrasi.",
        process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT
      );
      return;
    }
    if (profilePhoto.length !== 1) {
      message.error(
        "Tolong pilih foto anak untuk keperluan pembuatan sertifikat.",
        process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT
      );
      return;
    }

    this.setState({ buttonLoading: true });
    const fileParams = profilePhoto[0].type.split("/");
    const fileExtension = fileParams[fileParams.length - 1];
    try {
      const attId = await this.registerUser({
        ...values,
        photoContentType: fileExtension,
      });

      await this.uploadProfilePhoto(
        `${eventId}/${attId}_receipt.${fileExtension}`,
        paymentReceipt[0]
      );
      await this.uploadProfilePhoto(
        `${eventId}/${attId}_profile.${fileExtension}`,
        profilePhoto[0]
      );
      this.clearFormDataFromLocalStorage();
      history.push(`/thankyou/${eventId}/${attId}`);
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
      this.setState({ buttonLoading: false });
    }
  };

  loadFormDataFromLocalStorage = () => {
    // Set local storage default if exists
    let initialFormData;
    if (localStorage) {
      const dataString = localStorage.getItem(LOCAL_FORM_DATA_KEY);
      if (dataString && dataString !== "") {
        initialFormData = JSON.parse(dataString);
        if (this.formRef.current)
          this.formRef.current.setFieldsValue(initialFormData);
      }
    }
  };

  storeFormDataToLocalStorage = () => {
    // Just in case user's device has low memory, cache the form data
    // to local storage. we will clear on successful registration submission
    if (localStorage) {
      localStorage.setItem(
        LOCAL_FORM_DATA_KEY,
        JSON.stringify(this.formRef.current.getFieldsValue())
      );
    }
  };

  clearFormDataFromLocalStorage = () => {
    if (localStorage) {
      localStorage.setItem(LOCAL_FORM_DATA_KEY, null);
    }
  };

  PhotoUpload = ({ type = "profilePhoto" }) => {
    const { [type]: fileList } = this.state;
    return (
      <ImgCropIos
        rotate
        resizeMaxSize={parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE, 10)}
      >
        <Upload
          fileList={fileList}
          multiple={false}
          beforeUpload={(file) => {
            this.storeFormDataToLocalStorage();
            if (!file.type.startsWith("image/")) {
              message.error(`${file.name} is not an image file`);
              return false;
            }
            this.setState({
              [type]: [...fileList, file],
            });
            return false;
          }}
          onRemove={(file) => {
            this.setState((state) => {
              const index = state.file_list.indexOf(file);
              const newFileList = state.file_list.slice();
              newFileList.splice(index, 1);
              return {
                [type]: newFileList,
              };
            });
          }}
        >
          {fileList.length < 1 && <Button>Click to Upload</Button>}
        </Upload>
      </ImgCropIos>
    );
  };

  render() {
    const { recaptchaLoaded, event, buttonLoading, whatsapp } = this.state;
    return (
      <div className="site-layout-content">
        {!recaptchaLoaded || !event ? (
          <div>
            <Skeleton active />
            <Divider orientation="left" />
            <Space direction="vertical">
              <Skeleton.Input style={{ width: 300 }} active />
              <Skeleton.Input style={{ width: 300 }} active />
              <Skeleton.Input style={{ width: 300 }} active />
            </Space>
          </div>
        ) : (
          <Typography>
            <Row gutter={[15, 0]}>
              <Col md={15} style={{ paddingBottom: 15 }}>
                <Image style={{}} src={event.img} />
              </Col>
              <Col md={9}>
                <Title
                  className="main-color"
                  style={{ fontWeight: 800 }}
                  level={2}
                >
                  {event.name}
                </Title>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(event.description),
                  }}
                />
              </Col>
            </Row>
            <Divider orientation="left">
              <Text
                className="main-color"
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                }}
              >
                Event Date
              </Text>
            </Divider>
            <div style={{ textAlign: "" }}>
              <CalendarTwoTone />{" "}
              <Text>
                {differenceInCalendarDays(
                  fromUnixTime(event.end_date),
                  fromUnixTime(event.start_date)
                ) > 0 ? (
                  <Text>
                    {format(fromUnixTime(event.start_date), "d MMMM yyyy")}-{" "}
                    {format(fromUnixTime(event.end_date), "d MMMM yyyy")}
                  </Text>
                ) : (
                  <Text>
                    {format(
                      fromUnixTime(event.start_date),
                      "iiii, d MMMM yyyy"
                    )}
                  </Text>
                )}
              </Text>
            </div>
            <div>
              <ClockCircleTwoTone />{" "}
              <Text>
                {format(fromUnixTime(event.start_date), "HH:mm")}{" "}
                {format(fromUnixTime(event.start_date), "O") === "GMT+7"
                  ? "WIB"
                  : format(fromUnixTime(event.start_date), "O")}{" "}
              </Text>
            </div>
            <Divider orientation="left">
              <Text
                style={{ fontWeight: 600, fontSize: 20 }}
                className="main-color"
              >
                Registration Form
              </Text>
            </Divider>
            <Form
              ref={this.formRef}
              {...layout}
              onFinish={this.onFinish}
              hidden={
                event.attendanceCount >= event.max_attendance ||
                // eslint-disable-next-line no-underscore-dangle
                isAfter(new Date(), fromUnixTime(event.max_reg_date._seconds))
              }
            >
              <Form.Item
                name="name"
                label="Nama Anak"
                labelAlign="left"
                rules={[{ required: true }]}
              >
                <Input placeholder="Diisi nama lengkap" />
              </Form.Item>
              <Form.Item
                name="church"
                label="Gereja"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tolong masukkan nama gereja anda.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                labelAlign="left"
                rules={[
                  {
                    type: "email",
                    message:
                      "Tolong memasukkan email dengan format yang benar.",
                  },
                  {
                    required: true,
                    message: "Tolong memasukkan email.",
                  },
                ]}
                extra={
                  <ul>
                    <li>
                      Kami akan menggunakan email untuk pengiriman konfirmasi
                      pendaftaran dan sertifikat
                    </li>
                    <li>
                      Harap mengingat email yg digunakan krn utk memperoleh link
                      zoom.
                    </li>
                    <li>
                      Apabila bersaudara : tiap anak mendaftar dgn menggunakan
                      email yg berbeda.
                    </li>
                  </ul>
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="whatsapp"
                label="WhatsApp"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tolong masukkan nomor WhatsApp anda.",
                  },
                ]}
              >
                <PhoneInput
                  placeholder=""
                  defaultCountry="ID"
                  value={whatsapp}
                  onChange={(wa) => this.setState({ whatsapp: wa })}
                />
              </Form.Item>
              <Form.Item
                name="address"
                label="Alamat Rumah"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tolong masukkan alamat rumah anda.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="school"
                label="Sekolah"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tolong masukkan nama sekolah anda.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="kelas"
                label="Kelas"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tolong pilih kelas anda sebelum naik kelas.",
                  },
                ]}
              >
                <Select placeholder="Sebelum naik kelas">
                  <Select.Option value="Kelas 1">Kelas 1</Select.Option>
                  <Select.Option value="Kelas 2">Kelas 2</Select.Option>
                  <Select.Option value="Kelas 3">Kelas 3</Select.Option>
                  <Select.Option value="Kelas 4">Kelas 4</Select.Option>
                  <Select.Option value="Kelas 5">Kelas 5</Select.Option>
                  <Select.Option value="Kelas 6">Kelas 6</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="payment_receipt"
                label="Payment Receipt"
                labelAlign="left"
                tooltip="Foto bukti transfer"
              >
                <this.PhotoUpload type="paymentReceipt" />
              </Form.Item>
              <Form.Item
                name="profile_photo"
                label="Foto Anak"
                labelAlign="left"
                tooltip="Kami akan menggunakan foto anak untuk pembuatan sertifikat VBZ"
              >
                <this.PhotoUpload type="profilePhoto" />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  Register
                </Button>
              </Form.Item>
            </Form>

            {event.attendanceCount >= event.max_attendance && (
              <Text>
                Mohon maaf, pendaftaran telah ditutup karena jumlah peserta
                telah melebihi limit yang ditentukan
              </Text>
            )}

            {isAfter(
              new Date(),
              // eslint-disable-next-line no-underscore-dangle
              fromUnixTime(event.max_reg_date._seconds)
            ) && (
              <Text>
                Mohon maaf, pendaftaran telah ditutup karena telah melewati
                batas tanggal pendaftaran.
              </Text>
            )}
          </Typography>
        )}
      </div>
    );
  }
}

SMRegForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SMRegForm);
