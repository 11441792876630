import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Typography,
  Form,
  Input,
  Button,
  Divider,
  Row,
  Col,
  Skeleton,
  Image,
  Alert,
  Affix,
  notification,
  Space,
} from "antd";
import { useSelector, useDispatch } from "react-redux";

import PhoneInput from "react-phone-number-input";
import { fromUnixTime, isBefore, isAfter, format } from "date-fns";
import {
  getPollStatus,
  fetchPoll,
  getPollData,
  getSelectedQuestions,
  clearSelection,
} from "../../data/votingSlice";
import CheckboxGallery from "../CheckboxGallery";
import { FloatingCircle } from "../FloatingCircle";
import AddressSearch from "../AddressSearch";
import "react-phone-number-input/style.css";
import { SubmitConfirmationModal } from "../SubmitConfirmationModal";

const RECAPTCHA_KEY = "recaptcha-key";

const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
const SUBMIT_POLL_API = `${process.env.REACT_APP_SERVER_URL}/submitPollData`;

const { Title, Text, Paragraph } = Typography;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
  style: {
    marginTop: 0,
  },
};

const VotingForm = () => {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [name, setName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [address, setAddress] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { pollId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const poll = useSelector((state) => getPollData(state));
  const pollStatus = useSelector((state) => getPollStatus(state));
  const questions = useSelector((state) => getSelectedQuestions(state));

  const recaptchaScriptLoaded = () => {
    setRecaptchaLoaded(true);
  };

  useEffect(() => {
    if (!document.getElementById(RECAPTCHA_KEY) && !recaptchaLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = RECAPTCHA_URL;
      script.id = RECAPTCHA_KEY;
      script.onload = recaptchaScriptLoaded;
      document.body.appendChild(script);
    } else {
      setRecaptchaLoaded(true);
    }

    if (pollStatus === "idle") {
      dispatch(fetchPoll(pollId));
    } else if (pollStatus === "failed") {
      setErrorMessage(poll.error);
    }
  }, [pollStatus, dispatch]);

  const showError = (errorString) => {
    setErrorMessage(errorString);
    notification.error({ message: errorString });
  };

  const submitData = async () => {
    setButtonLoading(true);
    setShowConfirmationModal(false);
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: "submit" }
    );
    if (!token) {
      showError("Failed to verify recaptcha, please retry.");
      return;
    }
    const votingData = {
      token,
      selections: questions.map((q) => ({ id: q.id, name: q.name })),
      pollId,
      user: {
        name,
        address,
        cellPhone,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
      },
      body: JSON.stringify(votingData),
    };

    try {
      const response = await fetch(SUBMIT_POLL_API, requestOptions);
      const jsonResp = await response.json();
      if (jsonResp.result) {
        notification.success({
          message: "Data pemilihan anda telah terkirim, terima kasih",
        });
        history.push(`/pollthankyou/${jsonResp.voteId}`);
      } else {
        throw jsonResp.error;
      }
    } catch (error) {
      setButtonLoading(false);
      console.error(error);
      showError(error.toString());
    }
  };

  const onFinish = async () => {
    if (questions.length < poll.minSelection) {
      showError(
        `Mohon memilih sekurang-kurangnya ${poll.minSelection} calon, maximum ${poll.maxSelection} calon.`
      );
      return;
    }
    setShowConfirmationModal(true);
  };

  if (pollStatus === "failed") {
    return (
      <div className="site-layout-content">
        <Alert message={poll.error} showIcon type="error" />
      </div>
    );
  }
  if (isAfter(new Date(), fromUnixTime(poll.endDate))) {
    return (
      <div className="site-layout-content">
        <Paragraph>
          Pemilihan telah berakhir, terima kasih atas partisipasi anda.
        </Paragraph>
      </div>
    );
  }

  return (
    <div className="site-layout-content">
      {pollStatus === "loading" ? (
        <div>
          <Skeleton />
        </div>
      ) : (
        <div>
          <Title className="main-color">{poll.name}</Title>
          <Paragraph>{poll.description}</Paragraph>
          <Paragraph>
            <Image src={poll.banner} width="100%" preview={false} />
          </Paragraph>
          {poll.startDate &&
          isBefore(new Date(), fromUnixTime(poll.startDate)) ? (
            <Paragraph>
              Pemilihan akan dimulai pada tanggal{" "}
              {format(fromUnixTime(poll.startDate), "d MMMM yyyy, HH:mm")} WIB.
            </Paragraph>
          ) : (
            <div>
              <Divider orientation="left">
                <Text
                  style={{ fontWeight: 600, fontSize: 20 }}
                  className="main-color"
                >
                  Data Anda
                </Text>
              </Divider>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={({ errorFields }) => {
                  const err = errorFields.reduce(
                    (acc, e) => `${acc} ${acc ? " " : ""} ${e.errors[0]}`,
                    ""
                  );
                  setErrorMessage(err);
                }}
              >
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="name"
                      label="Nama"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Tolong masukkan nama anda.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Nama lengkap sesuai dengan KTP"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="cellPhone"
                      label="Nomor HP"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Tolong masukkan nomor nomor HP anda.",
                        },
                      ]}
                    >
                      <PhoneInput
                        placeholder=""
                        defaultCountry="ID"
                        value={cellPhone}
                        onChange={setCellPhone}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="address"
                  label="Alamat"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Tolong masukkan alamat lengkap anda.",
                    },
                  ]}
                >
                  <AddressSearch onChange={setAddress} />
                </Form.Item>
                <Row>
                  <Col md={24}>
                    <Divider orientation="left">
                      <Text
                        style={{ fontWeight: 600, fontSize: 20 }}
                        className="main-color"
                      >
                        Pemilihan Majelis
                      </Text>
                    </Divider>
                    {pollStatus === "loading" ? (
                      <div>
                        <Skeleton />
                      </div>
                    ) : (
                      <Paragraph>
                        Silahkan memilih calon-calon majelis di bawah ini (
                        <b>
                          Minimum: {poll.minSelection} - Maximum:{" "}
                          {poll.maxSelection}
                        </b>
                        )
                      </Paragraph>
                    )}
                    <CheckboxGallery />
                    <Affix
                      offsetBottom={15}
                      style={{ textAlign: "left", marginBottom: 15 }}
                    >
                      <FloatingCircle
                        text={`${questions.length}`}
                        style={{
                          backgroundColor:
                            poll.maxSelection === questions.length
                              ? "#87d068"
                              : "#2db7f5",
                          fontSize: "14pt",
                        }}
                        size="default"
                        title="Total Pilihan"
                      />
                    </Affix>
                  </Col>
                </Row>
                {errorMessage && (
                  <Alert
                    message={errorMessage}
                    showIcon
                    type="error"
                    style={{ marginBottom: 15 }}
                  />
                )}
                <Form.Item
                  wrapperCol={tailFormItemLayout.wrapperCol}
                  style={tailFormItemLayout.style}
                >
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        form.resetFields();
                        setErrorMessage("");
                        dispatch(clearSelection());
                      }}
                      loading={buttonLoading}
                      size="large"
                      danger
                    >
                      Hapus
                    </Button>

                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={buttonLoading}
                      style={{ width: "180px" }}
                    >
                      Kirim
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      )}

      <SubmitConfirmationModal
        isVisible={showConfirmationModal}
        onCancel={() => setShowConfirmationModal(false)}
        userInfo={{ name, cellPhone, address }}
        questions={questions}
        onOk={submitData}
      />
    </div>
  );
};

export default VotingForm;
