import React from "react";
import { AutoComplete, Typography, Space } from "antd";
import { EnvironmentTwoTone } from "@ant-design/icons";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";

const GOOGLE_MAPS_ID = "google-maps";

const { Text } = Typography;
class AddressSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleMapsLoaded: false,
      addressOptions: [],
    };
  }

  componentDidMount() {
    const { googleMapsLoaded } = this.state;
    if (!googleMapsLoaded && !window.google) {
      this.loadGoogleMaps();
    } else if (window.google) {
      this.initGoogleMap();
    }

    // link init google map to local method of this component
    window.initGoogleMap = this.initGoogleMap;
  }

  fetchPlacesAPIThrottled = (request, callback) => {
    throttle(this.fetchPlacesAPI, 200)(request, callback);
  };

  loadGoogleMaps = () => {
    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", GOOGLE_MAPS_ID);
    script.src =
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_API_KEY}&language=id&libraries=places` +
      `&callback=initGoogleMap`;
    document.querySelector("head").appendChild(script);
    this.setState({ googleMapsLoaded: true });
  };

  initGoogleMap = () => {
    this.setState({
      googleMap: new window.google.maps.places.AutocompleteService(),
    });
  };

  fetchPlacesAPI = (request, callback) => {
    const { googleMap } = this.state;
    if (!googleMap) {
      return;
    }
    googleMap.getPlacePredictions(request, callback);
  };

  createOptions = (result) =>
    result
      ? result.map((option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return {
            value: typeof option === "string" ? option : option.description,
            label: (
              <Space>
                <EnvironmentTwoTone />
                <Text>
                  {parts.map((part, i) => (
                    <span
                      // eslint-disable-next-line react/no-array-index-key
                      key={`parts${i}`}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Text>
                <Text>{option.structured_formatting.secondary_text}</Text>
              </Space>
            ),
          };
        })
      : null;

  handleSearch = (value) => {
    if (value === "") {
      this.setState({ addressOptions: [] });
      return;
    }
    this.fetchPlacesAPIThrottled(
      {
        input: value,
        type: ["address"],
        componentRestrictions: { country: "id" },
      },
      (result) => {
        this.setState({ addressOptions: this.createOptions(result) || [] });
      }
    );
  };

  render() {
    const { addressOptions } = this.state;
    const { onChange } = this.props;
    return (
      <div>
        <AutoComplete
          options={addressOptions}
          onSearch={this.handleSearch}
          onChange={onChange}
        />
      </div>
    );
  }
}
AddressSearch.defaultProps = {
  onChange: () => {},
};
AddressSearch.propTypes = {
  onChange: PropTypes.func,
};

export default AddressSearch;
