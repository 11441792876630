import React from "react";
import { useParams } from "react-router-dom";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

export const PollThankYou = () => {
  const { voteId } = useParams();

  return (
    <div className="site-layout-content">
      {voteId ? (
        <div>
          <Title className="main-color">Pemilihan Berhasil</Title>
          <Paragraph>
            Terima kasih atas partisipasi anda dalam pemilihan majelis.
            <br />
            <br />
            Berikut ini adalah id konfirmasi pemilihan anda: <b>{voteId}</b>
          </Paragraph>
        </div>
      ) : (
        <Paragraph>Halaman yang anda cari tidak ditemukan.</Paragraph>
      )}
    </div>
  );
};

export default PollThankYou;
