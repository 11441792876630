import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Spin, message } from "antd";

const GET_SHORTCUT_API = `${process.env.REACT_APP_SERVER_URL}/getShortcut`;

export const Shortcuts = () => {
  const { id } = useParams();
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    const loadEvent = async () => {
      try {
        const response = await fetch(`${GET_SHORTCUT_API}?sid=${id}`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
          },
        });
        const jsonResp = await response.json();
        const { event = null, error = null } = jsonResp;
        if (event) {
          const { id: eventId = null } = event;
          history.push(`/event/${eventId}`);
        } else if (error) {
          message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
          setErrorMsg(error);
        }
      } catch (error) {
        message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
      }
    };
    loadEvent();
  }, []);
  return (
    <div className="site-layout-content">
      {errorMsg ? <div>{errorMsg}</div> : <Spin />}
    </div>
  );
};

export default Shortcuts;
