import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, message, Spin, Button, Image } from "antd";
import { add, fromUnixTime, format, differenceInCalendarDays } from "date-fns";
import {
  CalendarTwoTone,
  ClockCircleTwoTone,
  YoutubeFilled,
} from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;
const GET_EVENT_API = `${process.env.REACT_APP_SERVER_URL}/getEventDetail`;

export const Musical = () => {
  const { id, attid } = useParams();
  const [event, setEvent] = useState({});

  const loadEvent = async () => {
    try {
      const response = await fetch(`${GET_EVENT_API}?eid=${id}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
        },
      });
      const jsonResp = await response.json();
      setEvent(jsonResp.event);
      document.title = jsonResp.event.name;
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
    }
  };

  useEffect(() => {
    loadEvent();
  }, []);

  return (
    <div className="site-layout-content">
      {id && attid ? (
        <div>
          <Paragraph>
            {!event.name ? (
              <Spin />
            ) : (
              <div style={{ textAlign: "center" }}>
                <Title className="main-color">{event.name}</Title>
                <Paragraph>
                  Thank you for filling the guest book. Please watch with us at
                  the following date:
                </Paragraph>
                <div style={{ textAlign: "" }}>
                  <CalendarTwoTone />{" "}
                  <Text>
                    {differenceInCalendarDays(
                      fromUnixTime(event.end_date),
                      fromUnixTime(event.start_date)
                    ) > 0 ? (
                      <Text>
                        {format(fromUnixTime(event.start_date), "d MMMM yyyy")}-{" "}
                        {format(fromUnixTime(event.end_date), "d MMMM yyyy")}
                      </Text>
                    ) : (
                      <Text>
                        {format(
                          fromUnixTime(event.start_date),
                          "iiii, d MMMM yyyy"
                        )}
                      </Text>
                    )}
                  </Text>
                </div>
                <div>
                  <ClockCircleTwoTone />{" "}
                  <Text>
                    {format(fromUnixTime(event.start_date), "HH:mm")}{" "}
                    {format(fromUnixTime(event.start_date), "O") === "GMT+7"
                      ? "WIB"
                      : format(fromUnixTime(event.start_date), "O")}{" "}
                    /{" "}
                    {format(
                      add(fromUnixTime(event.start_date), { hours: 1 }),
                      "h:mm a"
                    )}{" "}
                    SGT
                  </Text>
                </div>
                <br />
                {!event.youtube ? (
                  <Button
                    type="primary"
                    danger
                    href="https://gvc.gkagloria.id/taam"
                    target="_blank"
                    icon={<YoutubeFilled />}
                  >
                    COMING SOON
                  </Button>
                ) : (
                  <div className="video-container">
                    <iframe
                      style={{ width: "100%" }}
                      src={event.youtube.replace("watch?v=", "embed/")}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title="video"
                    />
                  </div>
                )}
              </div>
            )}
          </Paragraph>
          <div
            style={{ width: "100%", textAlign: "center", paddingTop: "1em" }}
          >
            <Image preview={false} src={event.img} />
          </div>
        </div>
      ) : (
        <Paragraph>Halaman yang anda cari tidak ditemukan.</Paragraph>
      )}
    </div>
  );
};

export default Musical;
