/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Typography,
  Form,
  Input,
  Button,
  message,
  Spin,
  Divider,
  Radio,
  Descriptions,
} from "antd";
import { CalendarTwoTone, ClockCircleTwoTone } from "@ant-design/icons";
import { fromUnixTime, format, differenceInCalendarDays } from "date-fns";

const { TextArea } = Input;
const { Title, Text } = Typography;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
  style: {
    marginTop: 15,
  },
};

const HEALTH_FORM_API = `${process.env.REACT_APP_SERVER_URL}/submitHealthForm`;
const RECAPTCHA_KEY = "recaptcha-key";
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
const GET_EVENT_API = `${process.env.REACT_APP_SERVER_URL}/getEventDetail`;

class RegForm extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      eventId: match.params.eventId,
      attId: match.params.attId,
      buttonLoading: false,
      recaptchaLoaded: false,
      showSicknessTextArea: false,
      showOutOfTownRadioGroup: false,
      event: null,
      attendance: null,
    };
  }

  componentDidMount = () => {
    if (!document.getElementById(RECAPTCHA_KEY)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = RECAPTCHA_URL;
      script.id = RECAPTCHA_KEY;
      script.onload = this.recaptchaScriptLoaded;
      document.body.appendChild(script);
    } else {
      this.setState({ recaptchaLoaded: true });
    }

    this.loadEvent();
  };

  loadEvent = async () => {
    const { eventId, attId } = this.state;
    try {
      const response = await fetch(
        `${GET_EVENT_API}?eid=${eventId}&aid=${attId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
          },
        }
      );
      const jsonResp = await response.json();
      this.setState({
        event: jsonResp.event,
        attendance: jsonResp.attendance,
      });
      document.title = jsonResp.event.name;
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
    }
  };

  recaptchaScriptLoaded = () => {
    this.setState({ recaptchaLoaded: true });
  };

  registerUser = async (values, token) => {
    const { event, eventId, attId } = this.state;
    const formData = event.health_form
      .filter((q) => q.id in values && values[q.id])
      .map((q) => ({
        ...q,
        answer: values[q.id],
      }));

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
      },
      body: JSON.stringify({
        token,
        event_id: eventId,
        attendance_id: attId,
        formData,
      }),
    };

    const response = await fetch(HEALTH_FORM_API, requestOptions);
    const jsonResp = await response.json();
    if (!jsonResp.result) {
      throw jsonResp.error;
    }
  };

  onFinish = async (values) => {
    this.setState({ buttonLoading: true });
    try {
      const token = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action: "submit" }
      );
      if (token && token !== "") {
        await this.registerUser(values, token);
        window.location.reload();
      } else {
        message.error(
          "Failed to verify recaptcha, please retry.",
          process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT
        );
        this.setState({ buttonLoading: false });
      }
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT);
      this.setState({ buttonLoading: false });
    }
  };

  render() {
    const {
      recaptchaLoaded,
      event,
      attendance,
      buttonLoading,
      showSicknessTextArea,
      showOutOfTownRadioGroup,
    } = this.state;
    // const trainingDate = event.training._seconds;
    return (
      <div className="site-layout-content">
        {!recaptchaLoaded || !event ? (
          <Spin />
        ) : (
          <Typography>
            <Title className="main-color" style={{ fontWeight: 800 }} level={2}>
              {event.name}
            </Title>

            <Divider orientation="left">
              <Text
                className="main-color"
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                }}
              >
                Informasi Ibadah
              </Text>
            </Divider>
            <Descriptions bordered>
              <Descriptions.Item label="Nama Lengkap" span={1.5}>
                {attendance.name}
              </Descriptions.Item>
              <Descriptions.Item label="PIC" span={1.5}>
                {event.pic}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Training" span={1.5}>
                <CalendarTwoTone />{" "}
                <Text>
                  {format(
                    fromUnixTime(event.training._seconds),
                    "iiii, d MMMM yyyy"
                  )}
                </Text>
                <br />
                <ClockCircleTwoTone />{" "}
                <Text>
                  {format(fromUnixTime(event.training._seconds), "HH:mm")}{" "}
                  {format(fromUnixTime(event.training._seconds), "O") ===
                  "GMT+7"
                    ? "WIB"
                    : format(fromUnixTime(event.training._seconds), "O")}{" "}
                </Text>
              </Descriptions.Item> */}
              <Descriptions.Item label="Ibadah" span={1.5}>
                <CalendarTwoTone />{" "}
                <Text>
                  {differenceInCalendarDays(
                    fromUnixTime(event.end_date),
                    fromUnixTime(event.start_date)
                  ) > 0 ? (
                    <Text>
                      {format(fromUnixTime(event.start_date), "d MMMM yyyy")}-{" "}
                      {format(fromUnixTime(event.end_date), "d MMMM yyyy")}
                    </Text>
                  ) : (
                    <Text>
                      {format(
                        fromUnixTime(event.start_date),
                        "iiii, d MMMM yyyy"
                      )}
                    </Text>
                  )}
                </Text>
                <br />
                <ClockCircleTwoTone />{" "}
                <Text>
                  {format(fromUnixTime(event.start_date), "HH:mm")}{" "}
                  {format(fromUnixTime(event.start_date), "O") === "GMT+7"
                    ? "WIB"
                    : format(fromUnixTime(event.start_date), "O")}{" "}
                </Text>
              </Descriptions.Item>
            </Descriptions>
            <Divider orientation="left">
              <Text
                style={{ fontWeight: 600, fontSize: 20 }}
                className="main-color"
              >
                Form Kesehatan
              </Text>
            </Divider>
            {attendance.health_form ? (
              <div>
                <Text>
                  Terima kasih, anda telah mengisi form kesehatan untuk{" "}
                  {event.name}
                  {", "}
                  {format(fromUnixTime(event.start_date), "iiii, d MMMM yyyy")}.
                </Text>
                {/* {attendance.health_form.map((q) => (
                  <div key={q.id}>
                    <Text>{q.question}</Text>
                    <Paragraph>
                      <Text
                        strong
                        type={
                          q.expected &&
                          q.expected.toLowerCase() !== q.answer.toLowerCase()
                            ? "danger"
                            : null
                        }
                      >
                        {q.answer}
                      </Text>
                    </Paragraph>
                    <Divider />
                  </div>
                ))} */}
              </div>
            ) : (
              <Form
                layout="vertical"
                onFinish={this.onFinish}
                initialValues={{
                  sickness: "Tidak",
                  vaccines: "Sudah 2x",
                  prokes: "Ya",
                  outOfTown: "Tidak",
                  saliveTest: "Ya, saya setuju",
                  saliveTestVideo: "Ya",
                }}
              >
                <Form.Item
                  name="sickness"
                  label="Apakah anda, atau anak anda, atau anggota keluarga inti (serumah) sedang sakit?"
                  labelAlign="left"
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        showSicknessTextArea: e.target.value === "Ya",
                      });
                    }}
                  >
                    <Radio value="Tidak">Tidak</Radio>
                    <Radio value="Ya">Ya</Radio>
                  </Radio.Group>
                </Form.Item>
                <Divider />
                {showSicknessTextArea && (
                  <>
                    <Form.Item
                      name="sicknessDesc"
                      label="Mohon menjelaskan jenis atau gejala dari penyakit anda, atau anak anda, atau anggota keluarga inti anda."
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message:
                            "Mohon menuliskan gejala penyakit yang di derita.",
                        },
                      ]}
                    >
                      <TextArea placeholder="Gejala penyakit" row={4} />
                    </Form.Item>
                    <Divider />
                  </>
                )}
                <Form.Item
                  name="vaccines"
                  label="Apakah anda telah vaksin?"
                  labelAlign="left"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value="Sudah 2x">Sudah 2x</Radio>
                    <Radio value="Sudah 1x">Sudah 1x</Radio>
                    <Radio value="Belum sama sekali">Belum sama sekali</Radio>
                  </Radio.Group>
                </Form.Item>
                <Divider />
                <Form.Item
                  name="prokes"
                  label="Ketika rekaman, apakah anda bersedia menaati prokes yang diberlakukan? (swab, double mask, tidak makan di tempat, menjaga social distancing)"
                  labelAlign="left"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value="Ya">Ya</Radio>
                    <Radio value="Tidak">Tidak</Radio>
                  </Radio.Group>
                </Form.Item>
                <Divider />
                <Form.Item
                  name="outOfTown"
                  label="Apakah anda dari luar kota?"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        showOutOfTownRadioGroup: e.target.value === "Ya",
                      });
                    }}
                  >
                    <Radio value="Tidak">Tidak</Radio>
                    <Radio value="Ya">Ya</Radio>
                  </Radio.Group>
                </Form.Item>
                <Divider />
                {showOutOfTownRadioGroup && (
                  <>
                    <Form.Item
                      name="outOfTownAntigen"
                      label="Apakah sebelumnya sudah melakukan test antigen?"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message:
                            "Mohon memberikan jawaban mengenai hasil tes antigen anda.",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="Ya, hasilnya negatif">
                          Ya, hasilnya negatif
                        </Radio>
                        <Radio value="Ya, hasilnya positif">
                          Ya, hasilnya positif
                        </Radio>
                        <Radio value="bellum">belum</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Divider />
                  </>
                )}
                <Form.Item
                  name="saliveTest"
                  label="Bila setelah melakukan Test Saliva dan hasilnya positif, maka saya setuju untuk TIDAK melanjutkan proses rekaman."
                  labelAlign="left"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value="Ya, saya setuju">Ya, saya setuju</Radio>
                    <Radio value="Tidak">Tidak</Radio>
                  </Radio.Group>
                </Form.Item>
                <Divider />
                <Form.Item
                  name="saliveTestVideo"
                  label="Saya telah melihat video cara memakai Test Saliva dan mengerti cara pakainya."
                  labelAlign="left"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value="Ya">Ya</Radio>
                    <Radio value="Tidak">Tidak</Radio>
                  </Radio.Group>
                </Form.Item>
                <Divider />
                <Form.Item
                  name="notes"
                  label="Keterangan tambahan yang anda ingin beritahu."
                  labelAlign="left"
                >
                  <TextArea rows={4} />
                </Form.Item>
                <Divider />
                <Text strong>
                  Dengan memencet tombol &ldquo;Kirim&ldquo; berikut ini, saya
                  menyatakan bahwa keterangan yang berikan di atas adalah benar.
                </Text>

                <Form.Item {...tailFormItemLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    loading={buttonLoading}
                  >
                    Kirim
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Typography>
        )}
      </div>
    );
  }
}

RegForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(RegForm);
