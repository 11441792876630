import React from "react";
import { Typography, Row, Col, Image, Divider } from "antd";

const { Title } = Typography;
const Default = () => (
  <div className="site-layout-content">
    <Typography>
      <Row align="middle">
        <Col flex="auto">
          <Title className="main-color">
            Welcome to
            <br />
            GKA GLORIA
            <br />
            Registration Portal.
          </Title>
        </Col>
        <Col flex="auto" style={{ textAlign: "center" }}>
          <Image src="https://storage.googleapis.com/gloria_assets_id/reg/reg-landing-small.png" />
        </Col>
      </Row>
      <Title level={3}>
        Please check our{" "}
        <a rel="noreferrer" href="https://satelit.gkagloria.id" target="_blank">
          website
        </a>{" "}
        or app to find an event that is available for registration.
      </Title>
      <Divider />
      <Row wrap>
        <Col span={12} style={{ textAlign: "center" }}>
          <a
            href="https://apl.gkagloria.id/android"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              preview={false}
              className="play-store-img"
              src="https://storage.googleapis.com/gloria_assets_id/reg/google-play-badge.svg"
            />
          </a>
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          <a
            href="https://apl.gkagloria.id/ios"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              preview={false}
              className="app-store-img"
              src="https://storage.googleapis.com/gloria_assets_id/reg/app-store-badge.svg"
            />
          </a>
        </Col>
        <Col />
      </Row>
    </Typography>
  </div>
);

export default Default;
