import React from "react";
import { Button } from "antd";

const VotingPageList = () => {
  const goTo = (page) => {
    window.open(`https://vote.gkagloria.id/${page}`, "_self");
  };
  return (
    <div className="site-layout-content">
      <div style={{ padding: 10 }}>
        <Button onClick={() => goTo("pacar")} block>
          GKA Gloria Pacar
        </Button>
      </div>
      <div style={{ padding: 10 }}>
        <Button onClick={() => goTo("satelit")} block>
          GKA Gloria Kota Satelit
        </Button>
      </div>
      <div style={{ padding: 10 }}>
        <Button onClick={() => goTo("galaxy")} block>
          GKA Gloria Galaxy
        </Button>
      </div>
      <div style={{ padding: 10 }}>
        <Button onClick={() => goTo("samudra")} block>
          GKA Gloria Samudra
        </Button>
      </div>
      <div style={{ padding: 10 }}>
        <Button onClick={() => goTo("nirwana")} block>
          GKA Gloria Nirwana
        </Button>
      </div>
      <div style={{ padding: 10 }}>
        <Button onClick={() => goTo("rungkut")} block>
          GKA Gloria Rungkut
        </Button>
      </div>
    </div>
  );
};

export default VotingPageList;
