/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  disabled: false,
  status: "idle",
  currentSelection: 0,
  maxSelection: 3,
};

const GET_POLL_API = `${process.env.REACT_APP_SERVER_URL}/getPollData`;
export const fetchPoll = createAsyncThunk("poll/getPoll", async (pollId) => {
  const response = await fetch(`${GET_POLL_API}/getPollData/?pid=${pollId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
    },
  });
  return response.json();
});

const votingSlice = createSlice({
  name: "voting",
  initialState,
  reducers: {
    questionSelected(state, action) {
      const { questions } = state;
      const question = questions.find((q) => q.id === action.payload.id);
      if (!question.selected) {
        if (state.currentSelection < state.maxSelection) {
          question.selected = !question.selected;
          state.currentSelection += 1;
        }
      } else {
        question.selected = !question.selected;
        state.currentSelection -= 1;
      }
    },
    clearSelection(state) {
      state.questions = state.questions.map((q) => ({
        ...q,
        selected: false,
      }));
      state.currentSelection = 0;
    },
  },
  extraReducers: {
    [fetchPoll.pending]: (state) => {
      state.status = "loading";
    },
    [fetchPoll.fulfilled]: (state, action) => {
      if (action.payload.result) {
        const {
          description,
          // eslint-disable-next-line camelcase
          max_selection,
          // eslint-disable-next-line camelcase
          min_selection,
          name,
          banner,
          // eslint-disable-next-line camelcase
          start_date,
          // eslint-disable-next-line camelcase
          end_date,
          selections,
        } = action.payload.data;
        state.status = "succeeded";
        state.name = name;
        state.banner = banner;
        // eslint-disable-next-line camelcase
        state.maxSelection = max_selection;
        // eslint-disable-next-line camelcase
        state.minSelection = min_selection;
        state.description = description;
        state.questions = selections;
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line no-underscore-dangle
        state.startDate = start_date._seconds;
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line no-underscore-dangle
        state.endDate = end_date._seconds;
      } else {
        state.status = "failed";
        state.error = action.payload.error;
      }
    },
    [fetchPoll.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectAllQuestions = (state) => state.poll.questions;
export const selectQuestionById = (state, id) =>
  state.poll.questions.find((question) => question.id === id);
export const getSelectedQuestions = (state) =>
  state.poll.questions ? state.poll.questions.filter((q) => q.selected) : [];
export const getPollStatus = (state) => state.poll.status;
export const getPollErrorMessage = (state) => state.poll.error;
export const getPollData = (state) => ({
  name: state.poll.name,
  description: state.poll.description,
  banner: state.poll.banner,
  maxSelection: state.poll.maxSelection,
  minSelection: state.poll.minSelection,
  startDate: state.poll.startDate,
  endDate: state.poll.endDate,
  disabled: state.poll.disabled,
  error: state.poll.error ? state.poll.error : "",
});

export const { questionSelected, clearSelection } = votingSlice.actions;
export default votingSlice.reducer;
